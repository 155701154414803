// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-caloriebalance-faq-index-js": () => import("./../../../src/pages/apps/caloriebalance/faq/index.js" /* webpackChunkName: "component---src-pages-apps-caloriebalance-faq-index-js" */),
  "component---src-pages-apps-caloriebalance-privacy-index-js": () => import("./../../../src/pages/apps/caloriebalance/privacy/index.js" /* webpackChunkName: "component---src-pages-apps-caloriebalance-privacy-index-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-privacy-index-js": () => import("./../../../src/pages/apps/privacy/index.js" /* webpackChunkName: "component---src-pages-apps-privacy-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-imprint-index-js": () => import("./../../../src/pages/imprint/index.js" /* webpackChunkName: "component---src-pages-imprint-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-templates-app-js": () => import("./../../../src/templates/app.js" /* webpackChunkName: "component---src-templates-app-js" */)
}

